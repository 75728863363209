.welcome-grid {
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.data-grid {
  margin: 0 auto !important;
  padding-top: 50px !important;
}
.person-image-dashboard {
  height: 100px !important;
}
.report-grid {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.report-div {
  padding-top: 50px !important;
}
.team-member-grid {
  background-color: #4700d5 !important;
  border-radius: 10% !important;
  display: flex !important;
  margin-bottom: 50px !important;

  justify-content: center !important;
  align-items: center !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.Volunteers-grid {
  background-color: #4292f9 !important;
  border-radius: 10% !important;
  display: flex !important;
  margin-bottom: 50px !important;

  justify-content: center !important;
  align-items: center !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.Doners-grid {
  background-color: #f8b53a !important;
  border-radius: 10% !important;
  display: flex !important;
  margin-bottom: 50px !important;

  justify-content: center !important;
  align-items: center !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.Donations-grid {
  border-radius: 10% !important;
  background-color: #e75656 !important;
  margin-bottom: 50px !important;
  display: flex !important;

  justify-content: center !important;
  align-items: center !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.volunteer-image {
  height: 80px !important;
  width: 80px !important;
  color: white !important;
}
.tables-grid {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
@media (min-width: 425px) and (max-width: 650px) {
  .dashboard-grid{
    margin-top: 70px !important;
  }
}
