.input-from {
  border: 1px solid #d9d4d4 !important;
}
.form-select {
  width: 100% !important;
  border: 1px solid #d9d4d4 !important;
}
.input-from-text {
  height: 100px !important;
}
.color-gray {
  background-color: rgb(0 0 0 / 68%) !important;
}
@media (max-width: 650px) {
  .component-header {
    justify-content: center !important;
    flex-direction: column !important;
    margin-bottom: 10px !important;
  }
}
