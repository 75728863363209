.login {
  background-color: #f36b21 !important;
  height: 100% !important ;
  // background-image: url('../../../common/icons/disable.jpg');
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: 100% 100%;
}
.login-form {
  width: 400px !important;
  box-sizing: border-box;
}
.img-forget-password {
  height: 200px !important;
  // border-radius: 30% !important;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.forget-password-form {
  width: 30% !important;
}
.forget-password {
  // background-color: #f36b21 !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important
  ;
}
.username-input {
  width: 100%;
}
.login-button {
  color: black !important;
  width: 50%;
  border-radius: 15px !important;
  height: 35px !important;
  background-color: white !important;
}
.forget-password-button{
  width: 50% !important;
border-radius: 15px !important;
height: 35px !important;
}

.person-image {
  width: 100%;
  height: 100%;
}
.sivabakkiyam-logo {
  width: 400px !important;
}
@media (min-width: 680px) and (max-width: 768px) {
  .login-form-main {
    background-color: #807a79 !important;
  }
}
@media (min-width: 425px) and (max-width: 768px) {
  .login-image {
    display: none !important;
  }
  .login-form {
    width: 300px !important;
    box-sizing: border-box;
  }
  .sivabakkiyam-logo {
    width: 300px !important;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  .login-image {
    display: none !important;
  }
  .login-form {
    width: 300px !important;
    box-sizing: border-box;
  }
  .sivabakkiyam-logo {
    width: 300px !important;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  .login-image {
    display: none !important;
  }
  .login-form {
    width: 300px !important;
    box-sizing: border-box;
  }
  .sivabakkiyam-logo {
    width: 300px !important;
  }
}
