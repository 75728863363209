.donation-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.chekbox-grid {
  width: 140px !important;
  justify-content: center;
  align-items: center;
  display: flex;
}
.checkbox-input-grid {
  flex-direction: row-reverse !important;
  justify-content: center !important;
  align-content: center !important;
}
.chekbox-grid-head{
display: flex !important;
}
.from-date-text{
  margin-top: -22px !important;
}
@media (min-width: 425px) and (max-width: 650px) {
  .chekbox-grid {
    width: 100px !important;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .donation-list-header {
    margin: 50px 0 50px 0;
  }
  .donations-form {
    width: 400px !important;
    margin-top: 50px !important;
  }
  .donation-header {
    margin-top: 50px !important;
    display: flex !important;
    flex-direction: column !important;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  .chekbox-grid {
    width: 80px !important;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .donation-list-header {
    margin: 50px 0 50px 0;
  }
  .donations-form {
    width: 400px !important;
    margin-top: 50px !important;
  }
  .donation-header {
    margin-top: 50px !important;
    display: flex !important;
    flex-direction: column !important;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  .chekbox-grid {
    width: 60px !important;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .donation-list-header {
    margin: 50px 0 50px 0;
  }
  .donations-form {
    width: 300px !important;
    margin-top: 50px !important;
  }
  .donation-header {
    margin-top: 50px !important;
    display: flex !important;
    flex-direction: column !important;
  }
}
